import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
  useMediaQuery,
  useTheme,
  AppBar,
  Toolbar,
  Link,
  Modal,
  TextField,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { MotionConfig, motion } from "framer-motion";
import GavelIcon from "@mui/icons-material/Gavel";
import PeopleIcon from "@mui/icons-material/People";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import StarsIcon from "@mui/icons-material/Stars";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import SecurityIcon from "@mui/icons-material/Security";
import fondoHome from "../images/fondoHome.jpg";
import fondoHome1 from "../images/fondoHome1.jpg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SpeedIcon from "@mui/icons-material/Speed";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";

import { submitContactForm } from "../api/contacts";

function HomePage() {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const texts = [
    "Abogados con experiencia comprobada",
    "Defendemos tus derechos con excelencia",
    "Soluciones legales rápidas y efectivas",
  ];
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [currentText, setCurrentText] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    success: true,
  });
  const handleOpen = (service) => {
    setSelectedService(service);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText((prev) => (prev + 1) % texts.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const services = [
    {
      title: "Servicios de Inmigración",
      icon: <StarsIcon sx={{ fontSize: 50, color: "#d19a62" }} />,
      description:
        "Nuestro amplio conocimiento del derecho migratorio y del derecho penal nos permite manejar cada aspecto de su caso de manera eficiente.",
    },
    {
      title: "Divorcio",
      icon: <GavelIcon sx={{ fontSize: 50, color: "#d19a62" }} />,
      description:
        "Ayudamos a las parejas que se divorcian a dividir la propiedad y los activos, así como las deudas en común.",
    },
    {
      title: "Defensa Criminal",
      icon: <PeopleIcon sx={{ fontSize: 50, color: "#d19a62" }} />,
      description:
        "Abogados de inmigración y criminal son una necesidad para cualquier persona que no es ciudadano y ha sido acusado de un crimen.",
    },
  ];
  const handleSubmitContact = async () => {
    setLoading(true);
    try {
      await submitContactForm(formData);
      setFormData({ name: "", email: "", message: "" });
      setOpen(false);
      setSnackbar({
        open: true,
        message: "Mensaje enviado correctamente",
        success: true,
      });
    } catch (error) {
      console.error("Error al enviar:", error);
      setSnackbar({
        open: true,
        message: "Ocurrió un error al enviar el mensaje",
        success: false,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      {/* Top Navigation Bar */}
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#00205B",
          backdropFilter: "blur(10px)",
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Link
            href="#"
            underline="none"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                letterSpacing: 1,
                color: "#E4FDE1", // un dorado claro o blanco verdoso
                fontSize: { xs: "1.2rem", md: "1.5rem" },
                textTransform: "uppercase",
              }}
            >
              C. Taboada Law Firm
            </Typography>
          </Link>

          <Button
            variant="contained"
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "block",
                xl: "block",
              },
              backgroundColor: "#E4FDE1",
              color: "#227C9D",
              fontWeight: "bold",
            }}
            onClick={() => {
              setSelectedService({
                title: "Agenda tu Consulta",
                description:
                  "Déjanos tus datos y nos pondremos en contacto contigo lo antes posible.",
              });
              setOpen(true);
            }}
          >
            Agenda tu Consulta
          </Button>
        </Toolbar>
      </AppBar>

      {/* Scroll Snapping Container */}
      <Box
        sx={{
          scrollSnapType: "y mandatory",
          overflowY: "scroll",
          height: "100vh",
        }}
      >
        <Snackbar
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            severity={snackbar.success ? "success" : "error"}
            sx={{ width: "100%" }}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
        {/* Hero Section */}
        <Box
          component="section"
          id="inicio"
          sx={{
            backgroundImage: `url(${fondoHome1})`,
            height: "100vh",
            px: 2,
            textAlign: "center",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            scrollSnapAlign: "start",
            "::before": {
              content: '""',
              position: "absolute",
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.6))",
              zIndex: 0,
            },
          }}
        >
          <Typography
            variant={isMobile ? "h4" : "h2"}
            fontWeight="bold"
            c
            textAlign="center"
            color="#d19a62"
            sx={{
              textShadow: "2px 2px 10px rgb(0, 0, 0)",
              px: 2,
              zIndex: 1,
            }}
          >
            {texts[currentText]}
          </Typography>
          <Typography
            variant="body1"
            color="#fff"
            sx={{
              mt: 2,
              maxWidth: 600,
              textShadow: "1px 1px 5px rgba(103, 99, 99, 0.53)",
              zIndex: 1,
            }}
          >
            Asesoría jurídica integral para empresas y personas.
          </Typography>
          <Box
            sx={{
              mt: 7,
              zIndex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              justifyItems: "center",
              textAlign: "center",
              alignContent: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                color: "#E4FDE1",
                backgroundColor: "#00205B",
                fontWeight: "bold",
                marginBottom: "2vh",
              }}
              onClick={() => {
                setSelectedService({
                  title: "Agenda tu Consulta",
                  description:
                    "Déjanos tus datos y nos pondremos en contacto contigo lo antes posible.",
                });
                setOpen(true);
              }}
            >
              Free Consulting
            </Button>
            <Button
              sx={{
                color: "#227C9D",
                backgroundColor: "#E4FDE1",
                fontWeight: "bold",
                marginBottom: "2vh",
              }}
              variant="outlined"
              onClick={() => {
                const section = document.getElementById("servicios"); // Cambia por el id que quieras
                if (section) {
                  section.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              Learn More
            </Button>
          </Box>
        </Box>
        {/* Services Section */}
        <Box
          component="section"
          id="servicios"
          sx={{
            scrollSnapAlign: "start",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "auto",
            backgroundColor: "#0d1c4e",
            height: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
              xl: "100%",
            },
          }}
        >
          <Grid
            container
            spacing={4}
            padding={3}
            sx={{
              paddingTop: {
                xs: "10vh",
                sm: "3vh",
                md: "0vh",
                lg: "0vh",
                xl: "0vh",
              },
            }}
          >
            {services.map((service, i) => (
              <Grid item xs={12} md={4} key={i}>
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: i * 0.2 }}
                >
                  <Card
                    onClick={() => handleOpen(service)}
                    sx={{
                      height: "60%",
                      textAlign: "center",
                      backgroundColor: "#E4FDE1",
                      cursor: "pointer",
                    }}
                  >
                    <CardContent>
                      <Box>{service.icon}</Box>
                      <Typography variant="h6" sx={{ mt: 2 }}>
                        {service.title}
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        {service.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>

          <Modal open={open} onClose={handleClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: "90%", md: 500 },
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                outline: "none",
              }}
            >
              {selectedService && (
                <>
                  <Typography variant="h6" gutterBottom>
                    {selectedService.title}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {selectedService.description}
                  </Typography>

                  <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                    ¿Deseas más información? Contáctanos:
                  </Typography>

                  <TextField
                    fullWidth
                    label="Nombre"
                    sx={{ mb: 2 }}
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                  <TextField
                    fullWidth
                    label="Correo Electrónico"
                    sx={{ mb: 2 }}
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                  <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    label="Mensaje"
                    sx={{ mb: 2 }}
                    value={formData.message}
                    onChange={(e) =>
                      setFormData({ ...formData, message: e.target.value })
                    }
                  />

                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmitContact}
                    disabled={loading}
                  >
                    {loading ? "Enviando..." : "Enviar Mensaje"}
                  </Button>
                </>
              )}
            </Box>
          </Modal>
        </Box>
        <Box
          component="section"
          id="servicios-legales"
          sx={{
            backgroundColor: "#f5f7f9",
            py: 10,
            px: 3,
            display: "flex",
            justifyContent: "center",
            scrollSnapAlign: "start",
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            maxWidth="md"
          >
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              Nuestros Servicios
            </Typography>

            {[
              {
                title: "Divorcio",
                description:
                  "Ayudamos a las parejas que se divorcian a dividir la propiedad y los activos, así como las deudas en común.",
              },
              {
                title: "Defensa Criminal",
                description:
                  "Abogados de inmigración y criminal son una necesidad para cualquier persona que no es ciudadano y ha sido acusado de un crimen. Su caso criminal puede tener implicaciones para su estado de inmigración, y si usted necesita un abogado que entienda todas las consecuencias.",
              },
              {
                title: "Servicios de Inmigración",
                description:
                  "Nuestro amplio conocimiento del derecho migratorio y del derecho penal nos permite manejar cada aspecto de su caso de manera eficiente.",
              },
            ].map((servicio, index) => (
              <Box key={index} sx={{ mb: 5 }}>
                <Typography variant="h6" fontWeight="bold">
                  {servicio.title}
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  {servicio.description}
                </Typography>
              </Box>
            ))}
          </Container>
        </Box>
        {/* About Section */}
        <Box
          component="section"
          id="nosotros"
          sx={{
            scrollSnapAlign: "start",
            flexDirection: "column",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            backgroundColor: "#0d1c4e", // azul paño
            color: "#fff",
            px: 2,
            textAlign: "center",
          }}
        >
          <Typography variant={isMobile ? "h5" : "h4"} fontWeight="bold">
            We Are Top Lawyers With{" "}
            <span style={{ color: "#d19a62" }}>25 Years</span> Of Experience
          </Typography>

          <Typography
            variant="body1"
            sx={{
              mt: 2,
              maxWidth: 800,
              mx: "auto",
              color: "#ccc",
            }}
          >
            At vero eos et accusamus et iusto odio dignissimos ducimus qui
            blanditiis praesentium voluptatum deleniti atque.
          </Typography>

          <Grid container spacing={4} justifyContent="center" sx={{ mt: 4 }}>
            {[
              {
                text: "100% Success Rate",
                icon: (
                  <CheckCircleIcon sx={{ color: "#d19a62", fontSize: 40 }} />
                ),
              },
              {
                text: "Expert Legal Service",
                icon: (
                  <SupportAgentIcon sx={{ color: "#d19a62", fontSize: 40 }} />
                ),
              },
              {
                text: "Quick Complete Case",
                icon: <SpeedIcon sx={{ color: "#d19a62", fontSize: 40 }} />,
              },
              {
                text: "Free Consulting",
                icon: <GavelIcon sx={{ color: "#d19a62", fontSize: 40 }} />,
              },
            ].map((item, idx) => (
              <Grid item xs={6} sm={3} key={idx}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: idx * 0.2 }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                  >
                    {item.icon}
                    <Typography
                      sx={{ mt: 1, fontWeight: "bold", color: "#fff" }}
                    >
                      {item.text}
                    </Typography>
                  </Box>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>
        {/* Stats Section */}
        <Box
          component="section"
          id="estadisticas"
          sx={{
            backgroundImage: `url(${fondoHome})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            scrollSnapAlign: "start",
            flexDirection: "column",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            backgroundColor: "#0d1c4e",
            color: "#fff",
            px: 2,
          }}
        >
          <Grid container spacing={4} justifyContent="center">
            {[
              {
                icon: <GavelIcon sx={{ fontSize: 50, color: "#d19a62" }} />,
                label: "Cases Solved",
                stat: "5,600 +",
              },
              {
                icon: <PeopleIcon sx={{ fontSize: 50, color: "#d19a62" }} />,
                label: "Cases Won",
                stat: "4,000 +",
              },
              {
                icon: (
                  <EmojiEventsIcon sx={{ fontSize: 50, color: "#d19a62" }} />
                ),
                label: "Awards Won",
                stat: "1,500 +",
              },
              {
                icon: <StarsIcon sx={{ fontSize: 50, color: "#d19a62" }} />,
                label: "Law Firms",
                stat: "Top 10",
              },
            ].map((item, idx) => (
              <Grid item xs={6} sm={3} key={idx}>
                <motion.div
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: idx * 0.2 }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    {item.icon}
                    <Typography variant="h6" sx={{ mt: 1 }}>
                      {item.stat}
                    </Typography>
                    <Typography variant="body2">{item.label}</Typography>
                  </Box>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>
        {/* Footer with contact form and social links */}
        <Box
          component="section"
          sx={{
            scrollSnapAlign: "start",
            backgroundColor: "#d19a62",
            color: "white",
            py: 6,
            px: 3,
            textAlign: "center",
            height: "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Box sx={{ padding: "2vh" }}>
                <Typography variant="h6" gutterBottom>
                  Contáctanos
                </Typography>
                <TextField
                  fullWidth
                  label="Nombre"
                  sx={{ mb: 2, backgroundColor: "#E5FFDE" }}
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
                <TextField
                  fullWidth
                  label="Correo Electrónico"
                  sx={{ mb: 2, backgroundColor: "#E5FFDE" }}
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
                <TextField
                  fullWidth
                  multiline
                  minRows={3}
                  label="Mensaje"
                  sx={{ mb: 2, backgroundColor: "#E5FFDE" }}
                  value={formData.message}
                  onChange={(e) =>
                    setFormData({ ...formData, message: e.target.value })
                  }
                />

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleSubmitContact}
                  disabled={loading}
                >
                  {loading ? "Enviando..." : "Enviar Mensaje"}
                </Button>
              </Box>
            </Grid>

            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
              item
              xs={12}
              md={4}
            >
              <Typography variant="h6" gutterBottom>
                Síguenos
              </Typography>
              <Box display="flex" justifyContent="center" gap={2}>
                <IconButton
                  href="https://www.instagram.com/carlotaboadalawfirmpllc/"
                  target="_blank"
                  sx={{ color: "white" }}
                >
                  <InstagramIcon />
                </IconButton>
                <IconButton
                  href="https://www.facebook.com/share/1R8dhzAMGS/"
                  target="_blank"
                  sx={{ color: "white" }}
                >
                  <FacebookIcon />
                </IconButton>
                <IconButton
                  href="Abogado@ctaboadalaw.com"
                  sx={{ color: "white" }}
                >
                  <EmailIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>

          <Typography variant="body2" sx={{ mt: 6 }}>
            &copy; {new Date().getFullYear()} Firma Legal. Todos los derechos
            reservados.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default HomePage;
